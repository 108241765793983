<template>
  <div class="reception-desk-modal-slide-wrapper">
    <simple-list v-if="listData.length" :data="listData"></simple-list>
    <div class="reception-desk-modal-button-wrapper">
      <clebex-button
        v-for="(actionButton, index) in listButtons"
        :key="index"
        :label="
          displayLabelName(
            'visitor-management',
            'reception-desks',
            actionButton.label
          )
        "
        :icon="actionButton.icon"
        @buttonPressed="actionButton.action"
      >
      </clebex-button>
    </div>
    <div v-if="message" class="reception-desk-modal-message">
      {{ this.displayLabelName("visitor-management", "messages", message) }}
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import ClebexButton from "@/components/global/Button";
import { getScreenId } from "@/services/helpers";
import { formatDate } from "@/services/helpers";
import { mapActions, mapState, mapGetters } from "vuex";
import visitStatuses, {
  S_CREATED,
  S_CHECKED_IN,
  S_CHECKED_OUT,
  S_ABSENT
} from "@/services/consts/modules/visitor-management/statuses";

export default {
  name: "ModalSlide",
  components: {
    SimpleList: defineAsyncComponent(() =>
      import("@/components/global/SimpleList")
    ),
    ClebexButton
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      message: null,
      buttons: {
        [S_CREATED]: [
          {
            ...visitStatuses[S_CHECKED_IN],
            label: "check-in",
            action: () => this.submitVisitorStatus(S_CHECKED_IN)
          },
          {
            label: "print-badge",
            icon: "#cx-vis1-print-badge",
            action: () => this.readQrCode(true)
          },
          {
            label: "print-check-in",
            icon: "#cx-vis1-print-check-in",
            action: () => this.readQrCode(false)
          }
        ],
        [S_CHECKED_IN]: [
          {
            label: "print-badge",
            icon: "#cx-vis1-print-badge",
            action: () => this.readQrCode(true)
          },
          {
            ...visitStatuses[S_ABSENT],
            action: () => this.submitVisitorStatus(S_ABSENT),
            label: "pause"
          },
          {
            ...visitStatuses[S_CHECKED_OUT],
            action: () => this.submitVisitorStatus(S_CHECKED_OUT)
          }
        ],
        [S_ABSENT]: [
          {
            ...visitStatuses[S_CHECKED_IN],
            label: "check-in",
            action: () => this.submitVisitorStatus(S_CHECKED_IN)
          },
          {
            ...visitStatuses[S_CHECKED_OUT],
            action: () => this.submitVisitorStatus(S_CHECKED_OUT)
          }
        ]
      }
    };
  },
  watch: {
    message: {
      handler(value) {
        if (!value) {
          return;
        }

        setTimeout(() => {
          this.message = null;
        }, 5000);
      }
    },
    scannedQRCode: {
      handler(code) {
        if (!code) {
          return;
        }
        const payload = {
          booking_id: this.modalData.visit.booking_id,
          qrcode: code,
          visitor_id: this.modalData.id,
          visit_id: this.modalData.visit.id
        };
        this.$store.commit("visitorManagement/setScannedQRCode", null, {
          root: true
        });
        this.printBadge(payload).then(response => {
          if (response && response.message) {
            this.message = "print-badge-success";

            // print badge only
            if (this.printOnly) {
              return;
            }
            // print badge and check-in
            this.submitVisitorStatus(S_CHECKED_IN);
          } else {
            this.message = "print-badge-error";
          }
        });
      }
    }
  },
  computed: {
    ...mapGetters("settings", ["globalDateFormat", "globalTimeFormat"]),
    ...mapState("visitorManagement", ["scannedQRCode", "modalQueue"]),
    modalData() {
      return this.modalQueue.find(e => e.visit.id === this.data.visit.id);
    },
    listData() {
      let listDataObject = [
        {
          title: this.displayLabelName(
            "visitor-management",
            "reception-desks",
            "visitor"
          ),
          content: this.modalData.full_name
        },
        {
          title: this.displayLabelName(
            "visitor-management",
            "reception-desks",
            "company"
          ),
          content: this.modalData.company
        },
        {
          title: this.displayLabelName(
            "visitor-management",
            "reception-desks",
            "host"
          ),
          content: this.modalData.host.full_name
        },
        {
          title: this.displayLabelName(
            "visitor-management",
            "reception-desks",
            "from"
          ),
          content: this.formatDate(this.modalData.visit.datetime_from)
        },
        {
          title: this.displayLabelName(
            "visitor-management",
            "reception-desks",
            "to"
          ),
          content: this.formatDate(this.modalData.visit.datetime_to)
        },
        {
          title: this.displayLabelName(
            "visitor-management",
            "reception-desks",
            "visitor-current-status"
          ),
          content: this.displayLabelName(
            "visitor-management",
            "reception-desks",
            visitStatuses[this.modalData.visit.visit_status_id].label
          )
        }
      ];
      return listDataObject;
    },
    listButtons() {
      return this.buttons[this.modalData.visit.visit_status_id];
    }
  },
  methods: {
    ...mapActions("visitorManagement", [
      "getVisitor",
      "changeVisitStatus",
      "printBadge",
      "setScanResource"
    ]),
    formatDate(arrivalDate) {
      return formatDate(
        arrivalDate,
        `${this.globalDateFormat} | ${this.globalTimeFormat}`
      );
    },
    readQrCode(printOnly) {
      this.printOnly = printOnly;
      this.setScanResource(true);
      this.$store.commit("visitorManagement/setQrScreenActive", true, {
        root: true
      });
      this.$store.commit(
        "qrCode/setQrScreenId",
        getScreenId(this.findElement()),
        {
          root: true
        }
      );
    },
    submitVisitorStatus(newStatus) {
      const { receptionDeskId } = this.$route.params;
      this.changeVisitStatus({
        visitId: this.modalData.visit.id,
        params: {
          reception_desk_id: receptionDeskId,
          visit_status_id: newStatus
        }
      }).then(() => {
        let data = this.modalQueue.map(v => {
          if (v.visit.id == this.modalData.visit.id) {
            v.visit.visit_status_id = newStatus;
          }
          return v;
        });

        this.$store.commit("visitorManagement/setModalQueue", data, {
          root: true
        });
      });
    }
  }
};
</script>
